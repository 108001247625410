// import React from "react";
// import { Route, Switch, withRouter } from "react-router-dom";
// // import {
// //   BrowserRouter as Router,
// //   Routes,
// //   Route,
// // } from "react-router-dom";
// import { Home } from "../pages/home";
// import { Portfolio } from "../pages/portfolio";
// import { ContactUs } from "../pages/contact";
// import { About } from "../pages/about";
// import { Socialicons } from "../components/socialicons";
// import { CSSTransition, TransitionGroup } from "react-transition-group";

// const AnimatedSwitch = withRouter(({ location }) => (
//   <TransitionGroup>
//     <CSSTransition
//       key={location.key}
//       timeout={{
//         enter: 400,
//         exit: 400,
//       }}
//       classNames="page"
//       unmountOnExit
//     >
//       < Switch location={location}>
//         <Route exact path="/" component={Home} />
//         <Route path="/about" component={About} />
//         <Route path="/portfolio" component={Portfolio} />
//         <Route path="/contact" component={ContactUs} />
//         <Route path="*" component={Home} />
//       </Switch>
//     </CSSTransition>
//   </TransitionGroup>
// ));

// function AppRoutes() {
//   return (
//     <div className="s_c">
//       <AnimatedSwitch />
//       <Socialicons />
//     </div>
//   );
// }

// export default AppRoutes;

import React from "react";
import { Route, Routes} from "react-router-dom";
import withRouter from "../hooks/withRouter"
import { Home } from "../pages/home";
import { Portfolio } from "../pages/portfolio";
import { ContactUs } from "../pages/contact";
import { About } from "../pages/about";
import { Socialicons } from "../components/socialicons";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const AnimatedRoutes = withRouter(({ location }) => (
  <TransitionGroup>
    <CSSTransition
      key={location.key}
      timeout={{
        enter: 400,
        exit: 400,
      }}
      classNames="page"
      unmountOnExit
    >
      <Routes location={location}>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </CSSTransition>
  </TransitionGroup>
));

function AppRoutes() {
  return (
    <div className="s_c">
      <AnimatedRoutes />
      <Socialicons />
    </div>
  );
}

export default AppRoutes;